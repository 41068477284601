import axios, { AxiosRequestConfig } from "axios";
import { error } from "console";
import { AuthResponse } from "types/types";
import { Urls } from "./api";

// export const API_URL = `http://testapi.amap.galex.md/api`
export const API_URL = `https://auth-api.amap.galex.md/api`
export const $apiAuth = axios.create({
    withCredentials:true,
    baseURL:Urls.authUrl
});
$apiAuth.interceptors.request.use((config)=>{
    config.headers.Authorization = `Bearer ${localStorage.getItem('token')}`;
    return config;
})
$apiAuth.interceptors.response.use((config)=>{
    return config;
}, async (error)=>{
    const originalRequest = error.config;
    if(error.response.status===401 && error.config && !error.config._isRetry){
        try{
    const response = await axios.get<AuthResponse>(`${process.env.REACT_APP_AUTH_API}/Token/refresh`,{withCredentials:true});
        localStorage.setItem('token',response.data.accessToken);
        return $apiAuth.request(originalRequest)
        }
        catch(e){
        }
}
})


