import { AxiosResponse } from "axios";
import { $api } from "http/api";
import { ReportColumn, ReportListItem, RequestReportData } from "types/reportTypes/reportTypes";

export default class ReportService{
    static async getReportsList():Promise<AxiosResponse<ReportListItem[]>>{
        return $api.get<ReportListItem[]>('/Report/getreports');
    }
    static async getReportColums(reportId:number):Promise<AxiosResponse<any[]>>{
      console.log("In getCol")
        return $api.get<any[]>('/Report/getcolumns',{params:{
            reportId:reportId
        }});
    }
    static async getDataReport(requestParams:RequestReportData):Promise<AxiosResponse<any>>{
        return $api.post<any>('/Report/getreportdata',{
            reportId:requestParams.reportId,
            currentPage:requestParams.currentPage,
            pageSize:requestParams.pageSize,
            sortColumn:requestParams.sortColumn,
            sortDirection:requestParams.sortDirection,
            searchFilter:requestParams.searchFilter,
            timeout:requestParams.timeout,
            reportGenericParam:requestParams.reportGenericParam
        });
    }
   
}