import axios, { AxiosRequestConfig } from "axios";
import { error } from "console";
import { AuthResponse } from "types/types";

// export const API_URL = `http://testapi.amap.galex.md/api`
export const Urls = {
    authUrl:`${process.env.REACT_APP_AUTH_API}`,
    dataUrl:`${process.env.REACT_APP_API}`
    // dataUrl:"https://2652-95-65-118-65.ngrok-free.app/api"

}
export const $api = axios.create({
    withCredentials:true,
    baseURL:Urls.dataUrl
});
$api.interceptors.request.use((config)=>{
    config.headers.Authorization = `Bearer ${localStorage.getItem('token')}`;
    return config;
})
$api.interceptors.response.use((config)=>{
    return config;
}, async (error)=>{
    const originalRequest = error.config;
    if(error.response.status===401 && error.config && !error.config._isRetry){
        try{
    const response = await axios.get<AuthResponse>(`${Urls.authUrl}/Token/refresh`,{withCredentials:true});
        localStorage.setItem('token',response.data.accessToken);
        return $api.request(originalRequest)
        }
        catch(e){
        }
}
})


