import React, { useEffect, useState } from "react";
import DashBoardService from "services/dashboard/DashBoardService";
import PCCardDashboard from "views/admin/default/components/PCCardDashboard";
import LineChartServer from "./components/LineChartServer";
import Card from "components/card";
import { lineChartDataTotalSpent, lineChartOptionsTotalSpent } from "variables/charts";
import { Agent } from "types/dashboardTypes/dashboardTypes";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import { Oval } from "react-loader-spinner"
// Separate component for the loader spinner
const LoaderSpinner = () => {
  return <div className="loader-spinner">Loading...</div>;
};

const Dashboard = () => {
  const [serverData, setServerData] = useState<Agent[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [selectedAgent, setSelectedAgent] = useState<Agent>(null);

  useEffect(() => {
    const fetchAgents = async () => {
      try {
        const response = await DashBoardService.getAgents();
        setServerData(response.data);
        setSelectedAgent(response.data[0]);
      } catch (e: any) {
        console.log(e);
      } finally {
        setIsLoading(false);
      }
    };
    fetchAgents();
  }, []);

  const [cardRenderIndex, setCardRenderIndex] = useState(0);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (cardRenderIndex < serverData.length) {
        setCardRenderIndex(cardRenderIndex + 1);
      }
    }, 500);

    return () => clearTimeout(timeout);
  }, [cardRenderIndex, serverData]);

  const [startDate, setStartDate] = useState<Date>();
  const handleAgentClick = (agent: Agent) => {
    setSelectedAgent(agent);
  };
  return (
    <div>
      {isLoading ? ( // Display loader spinner if isLoading is true
        <div className="flex items-center justify-center mt-5">
          <Oval
            height={50}
            width={50}
            color="#FF015A"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
            ariaLabel='oval-loading'
            secondaryColor="#FF015A"
            strokeWidth={2}
            strokeWidthSecondary={2}

          />
        </div>
      )
        : (
          <div className="mt-5 mb-5 grid grid-cols-1 gap-5 md:grid-cols-3">
            {serverData.map((agent) => (
              <div key={agent.id}>
                {isLoading ? ( // Display loader spinner for each PCCardDashboard component if isLoading is true
                  <LoaderSpinner />
                ) : (
                  <PCCardDashboard
                    agent={agent}
                    onclick={() => handleAgentClick(agent)}
                    bgColor={selectedAgent && selectedAgent.id === agent.id ? "shadow-[#FF015A]" : ""}
                  />
                )}
              </div>
            ))}
          </div>
        )}


      <Card extra={"w-full h-full  px-6"}>
        {!isLoading && (
          <div className="mt-8 min-h-[400px]">
            <div className="flex items-center justify-center flex-col gap-4">
              <h2 className="text-lg font-bold text-navy-700 dark:text-white">
                {selectedAgent.name}
              </h2>
            </div>
            <div className="flex min-w-[200px]"></div>
            <LineChartServer agent={selectedAgent} />
          </div>
        )}
      </Card>
    </div>
  );
};

export default Dashboard;