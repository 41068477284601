import React from "react";

// Admin Imports
import MainDashboard from "views/admin/default";
import NFTMarketplace from "views/admin/marketplace";
import Profile from "views/admin/profile";
import DataTables from "views/admin/tables";

// Auth Imports
import SignIn from "views/auth/SignIn";

// Icon Imports
import {
  MdHome,
  MdOutlineShoppingCart,
  MdBarChart,
  MdPerson,
  MdLock,
  MdFileDownload,
  MdPhoneAndroid,
  MdAdminPanelSettings,
} from "react-icons/md";
import ComplexTable from "views/admin/tables/components/ComplexTable";
import { UserListData } from "views/admin/tables/variables/tableDataUser";
const routes = [
  {
    secondary:true,
    name: "Main Dashboard",
    layout: "/admin",
    path: "default",
    icon: <MdHome className="h-6 w-6" />,
    component: <MainDashboard />,
  },
  {
    secondary:true,
    name: "Reports",
    layout: "/admin",
    icon: <MdBarChart className="h-6 w-6" />,
    path: "data-tables",
    component: <DataTables />,
  },
  {
    name: "Reports",
    layout: "/admin",
    icon: <MdBarChart className="h-6 w-6" />,
    secondary:false,
    path: "data-tables/:reportId",
    component: <ComplexTable />,
  },

  // {
  //   secondary:true,
  //   name: "Profile",
  //   layout: "/admin",
  //   path: "profile",
  //   icon: <MdPerson className="h-6 w-6" />,
  //   component: <Profile />,
  // },
  {
    secondary:false,
    name: "Sign In",
    layout: "/auth",
    path: "sign-in",
    icon: <MdLock className="h-6 w-6" />,
    component: <SignIn />,
  },
      {
    name: "Backend",
    layout: "/external",
    icon: <MdAdminPanelSettings className="h-6 w-6" />,
    secondary:false,
    path: `${process.env.REACT_APP_BACKEND}`,
    component:null,
  },
      {
    name: "Mobile App",
    layout: "/download",
    icon: <MdPhoneAndroid className="h-6 w-6" />,
    secondary:false,
    path: "/files/AMAP-app.apk",
    component: null,
  },

];
export default routes;
