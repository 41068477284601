import tableDataDevelopment from "./variables/tableDataDevelopment";
import tableDataCheck from "./variables/tableDataCheck";
import CheckTable from "./components/CheckTable";
import tableDataColumns from "./variables/tableDataColumns";
import tableDataComplex from "./variables/tableDataComplex";
import DevelopmentTable from "./components/DevelopmentTable";
import ColumnsTable from "./components/ColumnsTable";
import ComplexTable from "./components/ComplexTable";
import { UserListData } from "./variables/tableDataUser";
import { useEffect, useState } from "react";
import { ReportListItem } from "types/reportTypes/reportTypes";
import ReportService from "services/report/ReportService";
import ColumsTables from "./components/testTable";

const Tables = () => {

  const [reportData,setReportData] = useState<ReportListItem[]>([] as ReportListItem[]);
  useEffect(()=>{
      const fetch= async () =>{
        setReportData(await (await ReportService.getReportsList()).data);
      }
      fetch();
  },[])
  
  return (
    <div>



      <div className="mt-5  h-full grid-cols-1 gap-5 md:grid-cols-2">
       {/* <ComplexTable tableData={UserListData} /> */}
       {}
      
       <DevelopmentTable tableData={reportData}/>
       {/* <ColumnsTable/>
        <ComplexTable; */}
        {/* <DevelopmentTable tableData={tableDataDevelopment} />
        <CheckTable tableData={tableDataCheck} />
      </div>
      
      <div className="mt-5 grid h-full grid-cols-1 gap-5 md:grid-cols-2">
        <ColumnsTable tableData={tableDataColumns} />

        */}
      </div>
    </div>
  );
};

export default Tables;
