import { AxiosResponse } from "axios";
import { $api } from "http/api";
import { Agent, CpuLoadProgressChart, RAMLoadProgressBar } from "types/dashboardTypes/dashboardTypes";


export type ChartPropsFromApi = {
    options:string[],
    data:dataProp[]
}
export type dataProp={
    name:string,
    data:number[],
    color:string
}
export default class DashBoardService {
    static async getAgents(): Promise<AxiosResponse<Agent[]>> {
        return $api.get<Agent[]>('/Agent');
    }

    static async getCPULoad(AgentId: number): Promise<AxiosResponse<CpuLoadProgressChart[]>> {
        return $api.get<CpuLoadProgressChart[]>(`/Agent/LatestCPULoad/${AgentId}`);
    }
    static async getRAMLoad(AgentId: number): Promise<AxiosResponse<RAMLoadProgressBar>> {
        return $api.get<RAMLoadProgressBar>(`/Agent/LatestRAMLoad/${AgentId}`);
    }
    static async getLineChartData(agentId: number, startDate: string, endDate: string): 
    Promise<AxiosResponse<ChartPropsFromApi>> {
        if (startDate == "," && endDate == ",") {
            return $api.get<ChartPropsFromApi>(`/Dashboard/LineChart/${agentId}/${startDate}/${endDate}`);

        }
        const formattedStartDate = encodeURIComponent(startDate);
        const formattedEndDate = encodeURIComponent(endDate);
        return $api.get<any>(`/Dashboard/LineChart/${agentId}/${formattedStartDate}/${formattedEndDate}`);
    }
}