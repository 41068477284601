import { Routes, Route, Navigate, useLocation } from "react-router-dom";

import AdminLayout from "layouts/admin";
import AuthLayout from "layouts/auth";
import { useEffect, useState } from "react";
import { RootState } from "store/store";
import { useDispatch, useSelector } from "react-redux";
import { login,checkAuth } from "store/user/userSlice";
import UserService from "services/UserService";
import { Oval } from "react-loader-spinner";
import { Navigation } from "react-calendar";
const App = () => {
  const {user} = useSelector((state:RootState)=>state);
  const dispatch = useDispatch();
  const [loading,setIsLoading] = useState<boolean>(true);
  const location = useLocation();

  useEffect(() => {
    localStorage.setItem("lastVisitedRoute", location.pathname);
  }, [location.pathname]);

  useEffect(()=>{
    const fetch=async()=>{
      try{

        await UserService.checkAuthUser(dispatch);
      }
      catch(ex){
        console.log(ex);
      }
      finally{
        setIsLoading(false);
      }
    }
    fetch();
  },[])
  const lastVisitedRoute = localStorage.getItem("lastVisitedRoute");
  localStorage.removeItem("lastVisitedRoute");
  return (
    <>   
    {
      loading ? 
      (  <div className="flex justify-center items-center mx-auto">
      <Oval
          height={80}
          width={80}
          color="#FF015A"
          wrapperStyle={{}}
          wrapperClass=""
          visible={true}
          ariaLabel='oval-loading'
          secondaryColor="#FF015A"
          strokeWidth={2}
          strokeWidthSecondary={2}

        />
  </div>):(
    <Routes>
      <Route path="auth/*" element={user.isAuth ==false && !loading ?<AuthLayout />:<AdminLayout />} />
      <Route path="admin/*" element={user.isAuth ==true && !loading ? <AdminLayout />:<Navigate to="/auth"/>} />
      <Route path="/" element={<Navigate to={lastVisitedRoute || "/admin"} />} />
    </Routes>
    )
    }
    </>

  );
};

export default App;
