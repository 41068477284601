import {
  MdArrowDropUp,
  MdOutlineCalendarToday,
  MdBarChart,
} from "react-icons/md";
import Card from "components/card";
import {
  lineChartDataTotalSpent,
  lineChartOptionsTotalSpent,
  pieChartData,
  pieChartOptions,
} from "variables/charts";
import LineChart from "components/charts/LineChart";
import Progress from "components/progress";
import { CircularProgressbar, CircularProgressbarWithChildren, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { Agent, CpuLoadProgressChart, RAMLoadProgressBar } from "types/dashboardTypes/dashboardTypes";
import { useEffect, useState } from "react";
import DashBoardService from "services/dashboard/DashBoardService";
import { Oval } from "react-loader-spinner";
export type AgentProps = {
  agent: Agent,
  onclick: () => void,
  bgColor?:string,
}
const PCCardDashboard: React.FC<AgentProps> = ({ agent, onclick,bgColor }: AgentProps) => {
  const [CPULoad, setCPULoad] = useState<CpuLoadProgressChart[]>([] as CpuLoadProgressChart[]);
  const [RAMLoad, setRAMLoad] = useState<RAMLoadProgressBar>({} as RAMLoadProgressBar);
  const [isLoadingRam, setIsLoadingRam] = useState<boolean>(true);
  const [isLoadingCPU, setIsLoadingCPU] = useState<boolean>(true);
  useEffect(() => {
    const fetch = async () => {
      try {
        const responseCPU = await DashBoardService.getCPULoad(agent.id);
        const responseRAM = await DashBoardService.getRAMLoad(agent.id);
        setCPULoad(responseCPU.data);
        setRAMLoad(responseRAM.data);
      } catch (e: any) {
        console.log(e);
      }
      finally {
        setIsLoadingRam(false);
        setIsLoadingCPU(false);
      }
    }
    fetch();

  },
    [])
const [isSelected, setIsSelected] = useState<boolean>(false);

const handleClick = () => {
  // Set the selected state for the current card
  setIsSelected(true);
  // Call the onclick function
  onclick();
};
  return (
    <Card extra={`!p-[20px] text-center shadow-xl ${bgColor}`}>
      <div className="flex justify-center flex-col gap-4" onClick={handleClick}>
        <h2 className="text-lg font-bold text-navy-700 dark:text-white">
          {agent.name}
        </h2>

        <div>
          {
            isLoadingRam ? (<Oval
              height={30}
              width={30}
              color="#FF015A"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
              ariaLabel='oval-loading'
              secondaryColor="#FF015A"
              strokeWidth={2}
              strokeWidthSecondary={2}

            />
            ) :
              (
                <div>
                  <h3>
                    RAM
                  </h3>
                  <div>
                    <div className="flex justify-end">
                      {RAMLoad.name}
                    </div>
                    <Progress width="" value={RAMLoad.value} />
                  </div>
                </div>
              )
          }

        </div>
      </div>
      <div className="grid grid-cols-2 gap-1 md:grid-cols-3">
        {/* <PieChartCard/>
        <PieChartCard/>
        <PieChartCard/> */}
        
        {
          isLoadingCPU ?

            (
            <div className="flex items-center justify-center">
            <Oval
              height={30}
              width={30}
              color="#FF015A"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
              ariaLabel='oval-loading'
              secondaryColor="#FF015A"
              strokeWidth={2}
              strokeWidthSecondary={2}

            />
            </div>
          ) : (
              CPULoad.length > 0 ?(
            CPULoad.map((CPU, index) =>
            <div
              key={index}
              className="mt-5  flex-col justify-center items-center">
              <div>
                {CPU.type}
              </div>
              <div className="max-w-[150px] max-h-[150px]">
                <CircularProgressbarWithChildren
                  styles={buildStyles({ pathColor: `${CPU.color}` })}
                  value={CPU.value}
                  counterClockwise={true}

                >
                  {/* Put any JSX content in here that you'd like. It'll be vertically and horizonally centered. */}
                  <strong style={{ fontSize: 12 }}>{CPU.name}</strong>
                  <div style={{ marginTop: -5 }}>
                    <strong>{CPU.value}%</strong>
                  </div>
                </CircularProgressbarWithChildren>
                {/* <PieChart chartOptions={pieChartOptions} chartData={pieChartData} /> */}
              </div>
            </div>
          )
              ):(
                <div>
              
                  </div>
              )
          )
        }
      </div>
      <div className="flex h-full w-full flex-row justify-between sm:flex-wrap lg:flex-nowrap 2xl:overflow-hidden">
        <div className="h-full w-full">

        </div>
      </div>
    </Card>
  );
};

export default PCCardDashboard;
