import React, { useEffect, useMemo, useState } from "react";
import CardMenu from "components/card/CardMenu";
import Card from "components/card";
import Progress from "components/progress";
import { MdCancel, MdCheckCircle, MdOutlineError } from "react-icons/md";

import {
  Column,
  Table,
  useReactTable,
  ColumnFiltersState,
  getCoreRowModel,
  getFilteredRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFacetedMinMaxValues,
  getPaginationRowModel,
  sortingFns,
  SortingState,
  getSortedRowModel,
  FilterFn,
  SortingFn,
  ColumnDef,
  flexRender,
  FilterFns,
} from '@tanstack/react-table'
import {
  RankingInfo,
  rankItem,
  compareItems,
} from '@tanstack/match-sorter-utils'

import { UserTableItem } from "../variables/tableDataUser";
import { useParams } from "react-router-dom";
import ReportService from "services/report/ReportService";
import { } from "../filters/dateRangeFilter";
import { ReportRequestFilters } from "types/reportTypes/reportTypes";

const DateFilter:FilterFn<any> = (row, columnId, value) => {
  // Rank the item
  const date = row.getValue(columnId) as Date;

  const [start, end] = value;
  const startDate = new Date(start)
  const endDate = new Date(end);
  const dateTimeAll = new Date(date);
  //If one filter defined and date is null filter it
  if(start && end == undefined){
  return dateTimeAll.getTime() >= startDate.getTime()
  }else if(start == undefined && end){
  return dateTimeAll.getTime() <= endDate.getTime()
  }else if (start && end) {
  return dateTimeAll.getTime() >= startDate.getTime() && dateTimeAll.getTime() <= endDate.getTime()
  } else return true;
}


// const columns = columnsDataCheck;
export default function ComplexTable() {
  const [sorting, setSorting] = React.useState<SortingState>([]);
  // const columns = [
  //   columnHelper.accessor("username", {
  //     id: "username",
  //     header: () => (
  //      "UserName"
  //     ),
  //     cell: (info) => (
  //       <p className="text-sm font-bold text-navy-700 dark:text-white">
  //         {info.getValue()}
  //       </p>
  //     ),
  //   }),
  //   columnHelper.accessor("fullname", {
  //     id: "fullname",
  //     header: () => (
  //         "FullName"
  //     ),
  //     cell: (info) => (
  //       <div className="flex items-center">
  //         {info.getValue() === "Approved" ? (
  //           <MdCheckCircle className="text-green-500 me-1 dark:text-green-300" />
  //         ) : info.getValue() === "Disable" ? (
  //           <MdCancel className="text-red-500 me-1 dark:text-red-300" />
  //         ) : info.getValue() === "Error" ? (
  //           <MdOutlineError className="text-amber-500 me-1 dark:text-amber-300" />
  //         ) : null}
  //         <p className="text-sm font-bold text-navy-700 dark:text-white">
  //           {info.getValue()}
  //         </p>
  //       </div>
  //     ),
  //   }),
  //   columnHelper.accessor("role", {
  //     id: "role",
  //     header: () => (
  //     "Role"
  //     ),
  //     cell: (info) => (
  //         info.getValue()
  //     ),
  //   }),

  // ]; // eslint-disable-next-line

  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
    []
  )
  const { reportId } = useParams();
  const [cols, setCols] = useState([] as any[]);



  const [pageCount, setPageCount] = React.useState(0);
  const [totalItems, setTotalItems] = React.useState(0);
  const [globalFilter, setGlobalFilter] = React.useState('')
  const [dataTable, setDataTable] = React.useState([] as any[]);
  const columns = cols.map(val =>{ 

    if(val.reportColumnType == "DateRange"){
      return {
        header:val.name,
        accessorKey: val.columnName,
        enableColumnFilter: val.isFilter,
        filterFn: 'datefilter'
    }
    }
    return {
          header:val.name,
          accessorKey: val.columnName,
          enableColumnFilter: val.isFilter,
      }
  });
  const fuzzyFilter: FilterFn<any> = (row, columnId, value, addMeta) => {
    // Rank the item
  
    // Return if the item should be filtered in/out
    return null
  }
  const columnsFromCols = cols.map(val => {
    let object:any = {} as any
    if(val.reportColumnType == "DateRange"){
      object.header = val.name;
      object.accessorKey = val.columnName;
      object.enableColumnFilter =val.isFilter;
      object.filterFn = 'datefilter';
      return object;
    
    }
    else  if(val.reportColumnType == "NumberRange"){
      object.header = val.name;
      object.accessorKey = val.columnName;
      object.enableColumnFilter =val.isFilter;
      object.filterFn = 'number';
      return object;
    }
    else{
      object.header = val.name;
      object.accessorKey = val.columnName;
      object.enableColumnFilter = val.isFilter;
      return object;
    }
  });
  const updatedColumns: ColumnDef<any, any>[] = [...columnsFromCols]
  const table = useReactTable({
    data: dataTable,
    columns:updatedColumns,
    state: {
columnFilters,
      sorting,
      globalFilter,
    },
    filterFns: {
      'fuzzy': fuzzyFilter,
      'datefilter':DateFilter
    },
    manualPagination: true,
    pageCount: pageCount,
    autoResetPageIndex: false,
    onGlobalFilterChange: setGlobalFilter,
    onColumnFiltersChange: setColumnFilters,
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    manualFiltering:true,
    debugTable: true,
    debugHeaders: true,
    debugColumns: false,
  });


  useEffect(() => {
    const fetchColumns = async () => {
      try {
        const response = await ReportService.getReportColums(Number(reportId));
        setCols(response.data.sort((a, b) => a.sortOrder - b.sortOrder));
      } catch (error) {
        console.error("Error fetching columns:", error);
      }
    };
    fetchColumns();
  }, []);
  
  useEffect(() => {
    
    console.log("COLSS",columnFilters);
    const fetchData = async () => {
      
      try {
          
        const response = await ReportService.getDataReport({
          reportId: Number(reportId), currentPage: table.getState().pagination.pageIndex,
          pageSize: table.getState().pagination.pageSize,
          sortColumn: "DateCreated", sortDirection: "asc",
          searchFilter: "Login",
        });
        setDataTable(response.data.rows);
        setTotalItems(response.data.totalItems)

      } catch (error) {
        console.error("Error fetching columns:", error);
      }
      setPageCount(Math.round(totalItems / table.getState().pagination.pageSize) == 0 && totalItems > 0 
      ? 1:
      Math.round(totalItems / table.getState().pagination.pageSize) ) 
    };
    fetchData();
  }, [table.getState().pagination.pageIndex, table.getState().pagination.pageSize]);
  useEffect(() => {
    setPageCount(Math.round(totalItems / table.getState().pagination.pageSize) == 0 && totalItems > 0 
    ? 1:
    Math.round(totalItems / table.getState().pagination.pageSize) ) 
  }, [totalItems, table.getState().pagination.pageSize])


    const handleFiltersSearch = async () =>{
        try{
          const reportFilterParams:ReportRequestFilters[] = [];
          
        await columnFilters.map(item=> {
            if(item.id == "DateCreated"){
              reportFilterParams.push({id:"DateStart",value:(columnFilters
            .find(cf=>cf.id == "DateCreated").value as [any,any])[0].toString()}) 
            reportFilterParams.push({id:"DateEnd",value:(columnFilters
              .find(cf=>cf.id == "DateCreated").value as [any,any])[1].toString()}) 
          }
          if(item.id == "min1" || item.id == "min5" || item.id == "min15" ){
            if((item.value as [any,any])[0] != null && 
            (item.value as [any,any])[1] != null 
              && (item.value as [any,any])[0].toString()!= ''
              && (item.value as [any,any])[1].toString()!= ''
              ){
            reportFilterParams.push({id:item.id + "min",value:(columnFilters
              .find(cf=>cf.id == item.id).value as [any,any])[0].toString()}) 
              reportFilterParams.push({id:item.id + "max",value:(columnFilters
                .find(cf=>cf.id == item.id).value as [any,any])[1].toString()})
              } 
          }
          else if(item.id !="DateCreated")
          {
            reportFilterParams.push({id:item.id,value:item.value.toString()});
          }
          })

          await console.log("FilterParams",reportFilterParams);
          const response = await ReportService.getDataReport({
            reportId: Number(reportId), currentPage: table.getState().pagination.pageIndex,
            pageSize: table.getState().pagination.pageSize,
            sortColumn: "DateCreated", sortDirection: "asc",
            searchFilter: "Login",
            reportGenericParam:reportFilterParams
          });
          setDataTable(response.data.rows);
        }catch(ex:any){
          console.log(ex);
        }
    }



  return (
    <Card extra={"w-full h-full px-6 pb-6 sm:overflow-x-auto"}>
      <div className="relative flex items-center justify-between pt-4">
        <div className="text-xl font-bold text-navy-700 dark:text-white">
        </div>
        <CardMenu />
      </div>
      <div>
        <div className="flex justify-between">
        <div>
          <div className="font-bold text-navy-700 mt-5">
            Global Filter
          </div>
          <DebouncedInput
            value={globalFilter ?? ''}
            onChange={value => setGlobalFilter(String(value))}
            className="p-1 font-lg shadow border border-block"
            placeholder="Search all columns..."
          />
        </div>
   
        </div>
        <div className="font-bold text-navy-700 mt-5">
          Filters
        </div>


      <div className="flex justify-between">

<div>
        {table.getHeaderGroups().map((headerGroup) => (
          <div key={headerGroup.id} className="grid grid-cols-2 gap-5 md:grid-cols-6">
            {headerGroup.headers.map((header) => {
              if (header.column.getCanFilter()) {
                return (
                  <div
                    key={header.id}
                    className="pt-1 pb-2 pr-4 text-start"
                  >
                    <div className="items-center justify-between text-xs text-navy-700 pb-2 font-bold"
                    >
                      {
                        header.column.getCanFilter() ?
                          flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          ) : null}
                    </div>
                    {header.column.getCanFilter() ? (
                      <div>
                        <Filter column={header.column} table={table} type={header.column
                          .columnDef.filterFn.toString()} />
                      </div>
                    ) : null}
                  </div>
                )
                  ;
              }
            })}
          </div>
        ))}
        </div>
        <div className="pt-5">
        <button
        onClick={handleFiltersSearch}
        type="button" className="text-white bg-blue-700
         hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 
         font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex 
         items-center dark:bg-blue-600 dark:hover:bg-blue-700
          dark:focus:ring-blue-800">
            Search <svg className="w-3.5 h-3.5 ml-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10"><path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5h12m0 0L9 1m4 4L9 9">
          </path></svg>
          </button>
        </div>
        </div>
      </div>
      <div className="mt-8 overflow-x-scroll xl:overflow-x-hidden">
        <table className="w-full">
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id} className="!border-px !border-gray-400">
                {headerGroup.headers.map((header) => {
                  return (
                    <th
                      key={header.id}
                      colSpan={header.colSpan}

                      className="cursor-pointer border-b-[1px] border-gray-200 pt-4 pb-2 pr-4 text-start"
                    >
                      <div className="items-center justify-between  text-gray-500  text-base"
                        onClick={header.column.getToggleSortingHandler()}
                      >
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                        {{
                          asc: "▲",
                          desc: "▼",
                        }[header.column.getIsSorted() as string] ?? null}
                      </div>
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
          <tbody>
            {table
              .getRowModel()
              .rows
              .map((row) => {
                return (
                  <tr key={row.id}>
                    {row.getVisibleCells().map((cell) => {
                      return (
                        <td
                          key={cell.id}
                          className="min-w-[150px] border-white/0 py-3  pr-4"
                        >
                          <p className="text-sm font-bold text-navy-700 dark:text-white">
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext()
                            )}
                          </p>
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
          </tbody>
        </table>
        <div className="flex items-center gap-2 mt-5 justify-end">
          <button
            className="border rounded p-1"
            onClick={() => table.setPageIndex(0)}
            disabled={!table.getCanPreviousPage()}
          >
            {'<<'}
          </button>
          <button
            className="border rounded p-1"
            onClick={() => table.previousPage()}
            disabled={!table.getCanPreviousPage()}
          >
            {'<'}
          </button>
          <button
            className="border rounded p-1"
            onClick={() => table.nextPage()}
            disabled={!table.getCanNextPage()}
          >
            {'>'}
          </button>
          <button
            className="border rounded p-1"
            onClick={() => table.setPageIndex(table.getPageCount() - 1)}
            disabled={!table.getCanNextPage()}
          >
            {'>>'}
          </button>
          <span className="flex items-center gap-1">
            <div>Page</div>
            <strong>
              {table.getState().pagination.pageIndex + 1} of{' '}
              {table.getPageCount()}
            </strong>
          </span>

          <select
            value={table.getState().pagination.pageSize}
            onChange={e => {
              table.setPageSize(Number(e.target.value))
            }}
          >
            {[10, 20, 30, 40, 50].map(pageSize => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize}
              </option>
            ))}
          </select>
        </div>
        <div className="pt-5 flex items-center justify-end">{totalItems} Rows</div>
      </div>
    </Card>
  );
}
function Filter({
  column,
  table,
  type,
}: {
  column: Column<any, unknown>
  table: Table<any>
  type:string
}) {
  const firstValue = table
    .getPreFilteredRowModel()
    .flatRows[0]?.getValue(column.id)
  const rows = table.getPreFilteredRowModel().flatRows;
  const columnFilterValue = column.getFilterValue()

  const sortedUniqueValues = React.useMemo(
    () =>
      typeof firstValue === 'number' || type=="number"
        ? []
        : Array.from(column.getFacetedUniqueValues().keys()).sort(),
    [column.getFacetedUniqueValues()]
  )
  
  const parsedDate =  
  firstValue != null 
  ||firstValue != undefined  ? Date.parse(firstValue.toString()) : null;
  if(
    !isNaN(parsedDate) && type=="datefilter"
  )
  {
  
  
    return (
      <div>
        <input
          //min={min.toISOString().slice(0, 10)}
          onChange={(e) => {
            const val = e.target.value;
            column.setFilterValue((old:any = []) => [val ? val : undefined, old[1]]);
          }}
          type="date"
          value={(columnFilterValue as [any, any])?.[0] || ""}
        />
        {" to "}
        <input
          //max={max.toISOString().slice(0, 10)}
          onChange={(e) => {
            const val = e.target.value;
            column.setFilterValue((old:any = []) => [
              old[0],
              val ? val.concat("T23:59:59.999Z") : undefined
            ]);
          }}
          type="date"
          value={(columnFilterValue as [any, any])?.[1]?.slice(0, 10) || ""}
        />
      </div>
    );
  }
  return typeof firstValue === 'number' || type=="number" ? (
    <div>
      <div className="flex space-x-2">
        <DebouncedInput
          type="number"
          min={Number(column.getFacetedMinMaxValues()?.[0] ?? '')}
          max={Number(column.getFacetedMinMaxValues()?.[1] ?? '')}
          value={(columnFilterValue as [number, number])?.[0] ?? ''}
          onChange={value =>
            column.setFilterValue((old: [number, number]) => [value, old?.[1]])
          }
          placeholder={`Min ${
            column.getFacetedMinMaxValues()?.[0]
              ? `(${column.getFacetedMinMaxValues()?.[0]})`
              : ''
          }`}
          className="w-24 border shadow rounded"
        />
        <DebouncedInput
          type="number"
          min={Number(column.getFacetedMinMaxValues()?.[0] ?? '')}
          max={Number(column.getFacetedMinMaxValues()?.[1] ?? '')}
          value={(columnFilterValue as [number, number])?.[1] ?? ''}
          onChange={value =>
            column.setFilterValue((old: [number, number]) => [old?.[0], value])
          }
          placeholder={`Max ${
            column.getFacetedMinMaxValues()?.[1]
              ? `(${column.getFacetedMinMaxValues()?.[1]})`
              : ''
          }`}
          className="w-24 border shadow rounded"
        />
      </div>
      <div className="h-1" />
    </div>
  ) : (
    <>
      <datalist id={column.id + 'list'}>
        {sortedUniqueValues.slice(0, 5000).map((value: any) => (
          <option value={value} key={value} />
        ))}
      </datalist>
      <DebouncedInput
        type="text"
        value={(columnFilterValue ?? '') as string}
        onChange={value => column.setFilterValue(value)}
        placeholder={`Search`}
        className="w-36 border shadow rounded"
        list={column.id + 'list'}
      />
      <div className="h-1" />
    </>
  )
}





function DebouncedInput({
  value: initialValue,
  onChange,
  debounce = 500,
  ...props
}: {
  value: string | number
  onChange: (value: string | number) => void
  debounce?: number
} & Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'>) {
  const [value, setValue] = React.useState(initialValue)

  React.useEffect(() => {
    setValue(initialValue)
  }, [initialValue])

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value)
    }, debounce)

    return () => clearTimeout(timeout)
  }, [value])

  return (
    <input {...props} value={value} onChange={e => setValue(e.target.value)} />
  )
}
