import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import "./index.css";
import React from "react";
import App from "./App";
import { Provider } from 'react-redux'
import { store } from "store/store";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
const root = ReactDOM.createRoot(document.getElementById("root"));
  
root.render(
  <BrowserRouter>
     <LocalizationProvider dateAdapter={AdapterMoment}>
  <Provider store={store}>
    <App />
    </Provider>
    </LocalizationProvider>
  </BrowserRouter>
);
