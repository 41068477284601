import { AuthResponse } from "types/types"
import { AxiosResponse } from "axios"
import { $apiAuth } from "http/apiAuth"
export default class AuthService{
    static async login(username:string,password:string,DeviceGuidId:string):Promise<AxiosResponse<AuthResponse>>{
        return $apiAuth.post<AuthResponse>('/Auth/login',{username,password,DeviceGuidId})
    }
    static async register(username:string,password:string):Promise<AxiosResponse<AuthResponse>>{
        return $apiAuth.post<AuthResponse>('/registration',{username,password})
    }
    static async logout():Promise<void>{
        return $apiAuth.post('/Token/logout')
    }
}