import React, { Component } from "react";
import ReactApexChart from "react-apexcharts";
import DashBoardService from "services/dashboard/DashBoardService";
import "react-datepicker/dist/react-datepicker.css";
import 'react-datepicker/dist/react-datepicker-cssmodules.css';
import DatePicker from "react-datepicker";
import { Agent } from "types/dashboardTypes/dashboardTypes";
import moment from "moment";
import { start } from "repl";
import { Oval } from "react-loader-spinner";
interface LineChartServerProps {
  agent?: Agent;
}

interface LineChartServerState {
  series: any[],
  options: any,
  startDate: Date,
  endDate: Date,
  isLoading: boolean
}

class LineChartServer extends Component<LineChartServerProps, LineChartServerState> {
  constructor(props: LineChartServerProps) {
    super(props);

    this.state = {
      options: {
        legend: {
          show: true,
        },
        theme: {
          mode: "light",
        },
        chart: {
          height: 350,
          type: "line",
        },
        forecastDataPoints: {
          count: 7,
        },
        stroke: {
          width: 5,
          curve: "smooth",
        },
        xaxis: {
          type: "text",
          categories: [],
          tickAmount: 10,
        },
        yaxis: {},
      },
      series: [],
      startDate: getStartAndEndDateOfMonth().startDate,
      endDate: getStartAndEndDateOfMonth().endDate,
      isLoading: true
    };

    this.updateCharts = this.updateCharts.bind(this);
  }
  async componentDidMount() {
    try {
      this.updateCharts();

    } catch (ex) {
      console.log(ex);
    }
  }

    componentDidUpdate(prevProps: LineChartServerProps) {
      if (prevProps.agent !== this.props.agent) {
        this.updateCharts();
      }
    }




  async updateCharts() {
    try {
      this.setState({
        isLoading:true
      })

      const responce = await DashBoardService.getLineChartData(this.props.agent.id,
        moment(this.state.startDate).format("DD/MM/yyyy hh:mm"),
        moment(this.state.endDate).format("DD/MM/yyyy hh:mm")
      )
        if(!responce){
          this.setState({
            options: {
              ...this.state.options,
              xaxis: {
                ...this.state.options.xaxis,
                categories: [],
              },
            },
            series: [],
          });
        }

      if (responce.data.data.length > 0) {
        this.setState({
          options: {
            ...this.state.options,
            xaxis: {
              ...this.state.options.xaxis,
              categories: responce.data.options,
            },
          },
          series: responce.data.data,
        });
      }
    }
    catch (ex) {
      console.log(ex);
    }
    finally {
      this.setState({
        isLoading: false
      })
    }
  }

  render() {
    return (
      <div>
        {this.state.isLoading ? (
          <div className="flex justify-center items-center">
          <Oval height={50} width={50} color="#FF015A" />
          </div>

        ):
        (
        <div >
          <div className="flex  justify-end mb-5">
            <DatePicker
              className="px-5 py-2.5 dark:text-white dark:bg-brand-400"
              selected={this.state.startDate}
              onChange={(date) => this.setState({
                startDate: date
              })}
              showTimeSelect
              placeholderText="dd/MM/yyyy hh:mm"
              dateFormat="dd/MM/yyyy hh:mm"
            />
            <div
              className="px-5 py-2.5  dark:text-white "
            >
              -
            </div>
            <DatePicker
              className="px-5 py-2.5 dark:text-white dark:bg-brand-400"
              selected={this.state.endDate}
              onChange={(date) => this.setState({
                endDate: date
              })}
              showTimeSelect
              placeholderText="dd/MM/yyyy hh:mm"
              dateFormat="dd/MM/yyyy hh:mm"
            />
            <button type="button"
              onClick={() => this.updateCharts()}
              className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
              Show
              <svg className="w-3.5 h-3.5 ml-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5h12m0 0L9 1m4 4L9 9" />
              </svg>
            </button>

          </div>
          { this.state.series.length > 0 ? (

            <ReactApexChart
            type="line"
            height={350}
            options={this.state.options}
            series={this.state.series}
            />
            ) :(
              <div className="bg-[#FF015A] border-t-4 border-[#FF015A] rounded-b text-white px-4 py-3 shadow-md" role="alert">
              <div className="flex">
                <div className="py-1">
                  <svg className="fill-current h-6 w-6 text-white mr-4" 
                  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                    <path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z"/>
                    </svg>
                    </div>
                <div>
                  <p className="font-bold">No data</p>
                  <p className="text-sm">Try o change Server or DataFilter</p>
                </div>
              </div>
            </div>
            )
          }
       
        </div>
        )
        }
      </div>
    );
  }
}

export default LineChartServer;

function getStartAndEndDateOfMonth(): { startDate: Date; endDate: Date } {
  const today = new Date();
  const year = today.getFullYear();
  const month = today.getMonth();

  // Start date of the month
  const startDate = new Date(year, month, 1);

  // Last day of the next month, then subtracting a day to get the end date of the current month
  const endDate = new Date(year, month + 1, 0);

  return { startDate, endDate };
}