import AuthService from "./AuthService";
import { v4 as uuid } from 'uuid';
import { checkAuth, login, logout } from "store/user/userSlice";
import { AuthResponse } from "types/types";
import axios from "axios";
import { Urls } from "http/api";

export default class UserService{
    static async loginUser(dispatch:any,username:string,password:string){
        
        try{
            let deviceID = uuid();
            const response = await AuthService.login(username,password,deviceID);
            localStorage.setItem('token',response.data.accessToken);
            dispatch(login(response.data.user))
            
        }
        catch(e:any){
            console.log(e.response?.data?.message)
        }
    }
    static async logoutUser(dispatch:any){
        try {
            const response = await AuthService.logout();
            localStorage.removeItem('token');
            dispatch(logout())
        }
        catch (e: any) {
            console.log(e.response?.data?.message)
        }
        
    }
    static async checkAuthUser(dispatch:any){
        try{
                    const response = await axios.get<AuthResponse>(`${Urls.authUrl}/Token/refresh`,{withCredentials:true})
                    localStorage.setItem('token', response.data.accessToken);
                    dispatch(checkAuth(response.data.user));
        }
        catch(e:any){
            console.log(e.response?.data?.message)
        }
        finally{
        }
    }
}