import React, { useEffect } from "react";
import CardMenu from "components/card/CardMenu";
import { DiApple } from "react-icons/di";
import { DiAndroid } from "react-icons/di";
import { DiWindows } from "react-icons/di";
import Card from "components/card";
import Progress from "components/progress";

import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";
import { useNavigate } from "react-router-dom";
import { ReportListItem } from "types/reportTypes/reportTypes";
import { getDateInFormat_yyyy_mm_dd } from "utils/helpers/dateToString";

const columns = [
  // first group - TV Show
  {
    header: "Report Name",
    accessorKey: "name",
    enableColumnFilter :false,
  },
  {
    header: "Date",
    accessorKey: "dateCreated",
    enableColumnFilter :false,
  }
]
function CheckTable(props: { tableData:any }) {

  const navigate = useNavigate();
  const { tableData } = props;
  const [sorting, setSorting] = React.useState<SortingState>([]);
  let defaultData = tableData;

  // const datafortable : any = [];
  // tableData.map((data:any)=>{
  //   datafortable.push(columnHelper.accessor("name", {
  //     id: "name",
  //     header: () => (
  //       <p className="text-sm font-bold text-gray-600 dark:text-white">NAME</p>
  //     ),
  //     cell: (info:any) => (
  //       <p className="text-sm font-bold text-navy-700 dark:text-white">
  //         {info.getValue()}
  //       </p>
  //     ),
  //   }))
  // })

  const [data, setData] = React.useState(() => [...defaultData]);
  useEffect(() => {
    setData(tableData);
  }, )
  
  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
    },
    filterFns:{
      'fuzzy':null,
      'datefilter':null,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: true,
  });
  return (
    <Card extra={"w-full h-full sm:overflow-auto px-6"}>
      {/* <header className="relative flex items-center justify-between pt-4">
  
        <CardMenu />
      </header> */}

      <div className=" overflow-x-scroll xl:overflow-x-hidden">
        <table className="w-full">
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id} className="
             
              !border-px !border-gray-400"
             
              >
                {headerGroup.headers.map((header) => {
                  return (
                    <th
                      key={header.id}
                      colSpan={header.colSpan}
                      onClick={header.column.getToggleSortingHandler()}
                      className="cursor-pointer border-b-[1px] border-gray-200 pt-4 pb-2 pr-4 text-start"
                    >
                      <div className="items-center justify-between text-xs text-gray-200">

                
                      <p className="text-sm font-bold text-gray-600 dark:text-white">
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                        </p>
                        {{
                          asc: "",
                          desc: "",
                        }[header.column.getIsSorted() as string] ?? null}
                      </div>
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
          <tbody>
            {table
              .getRowModel()
              .rows.slice(0, 5)
              .map((row) => {
                return (
                  
                  <tr key={row.id}  onClick={()=>navigate(`${row.original.id.toString()}`)} className="cursor-pointer">
                    {row.getVisibleCells().map((cell) => {
                      return (
                        <td
                          key={cell.id}
                          className="min-w-[150px] border-white/0 py-3  pr-4"
                        >
                          {flexRender(
                            cell.column.columnDef.cell,
                            
                            cell.getContext()
                          )}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
    </Card>
  );
}

export default CheckTable;
const columnHelper = createColumnHelper<ReportListItem>();
